





















import { Component, Vue } from "vue-property-decorator";
import { db } from "@/scripts/firebase";
import VideoInfoCard from "@/components/VideoInfoCard.vue";
import VideoListPickup from "@/components/VideoListPickup.vue";

@Component({
  components: {
    VideoInfoCard,
    VideoListPickup
  }
})
export default class VideoInfoView extends Vue {
  videoData: any | undefined = {};
  modelVideos: any | undefined = [];
  seriesVideos: any | undefined = [];

  modelURL = "";
  seriesURL = "";

  created() {
    const collectionRef = db.collection("videos");
    const docRef = collectionRef.doc(this.$route.params.productId);
    docRef
      .get()
      .then(doc => {
        if (!doc.exists) {
          console.log("No such document!");
        } else {
          console.log("Document data:", doc.data());
          this.videoData = doc.data();

          // 遷移先のURL
          this.modelURL = `/model-info/${this.videoData.model}`;
          this.seriesURL = `/series/${this.videoData.series}`;

          // 作品に関連する女優の作品のデータを取得する。
          collectionRef
            .where("model", "==", this.videoData.model)
            .get()
            .then(querySnapshot => {
              querySnapshot.forEach(doc => {
                console.log(doc.id, " => ", doc.data());
                this.modelVideos.push(doc.data());
              });
            })
            .catch(function(error) {
              console.log("Error getting documents: ", error);
            });

          // 作品に関連するシリーズの作品のデータを取得する。
          collectionRef
            .where("series", "==", this.videoData.series)
            .get()
            .then(querySnapshot => {
              querySnapshot.forEach(doc => {
                console.log(doc.id, " => ", doc.data());
                this.seriesVideos.push(doc.data());
              });
            })
            .catch(function(error) {
              console.log("Error getting documents: ", error);
            });
        }
      })
      .catch(err => {
        console.log("Error getting document", err);
      });
  }

  //--- ピックアップ動画のHeading --//
  getModelVideoHeading(value: string) {
    const heading = `${value}の作品`;
    return heading;
  }

  getSeriesVideoHeading(value: string) {
    const heading = `${value}の作品`;
    return heading;
  }
}
