




















import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class VideoInfoCard extends Vue {
  @Prop() readonly video: any | undefined;
}
