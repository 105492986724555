














import { Component, Prop, Vue } from "vue-property-decorator";
import VideoListItem from "@/components/VideoListItem.vue";

@Component({
  components: {
    VideoListItem
  }
})
export default class VideoListPickup extends Vue {
  @Prop({ type: String, required: true }) heading: string | undefined;
  @Prop() videos: any | undefined;
  @Prop() url: string | undefined;
}
